import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import HowToPayPic from "assets/img/static/bg/howToPay.png";
import AirtelMoneyLogo from "assets/img/static/icon/airtel-money.png";
import MomoLogo from "assets/img/static/icon/momo.png";
import MainContainer from "parts/mainContainer";
import React from "react";

interface HowToPayProps {}

const HowToPay: React.FC<HowToPayProps> = () => {
  const paymentMethodsLogos = [
    {
      label: "MoMo",
      image: MomoLogo,
    },
    {
      label: "Airtel Money",
      image: AirtelMoneyLogo,
    },
    // {
    //   label: "Cards",
    //   image: CardsLogo,
    // },
  ];
  return (
    <>
      <section>
        <MainContainer>
          <Box pt={5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} className="p-center">
                <Box>
                  <h1 className="heading-2">How to pay ?</h1>
                  <Typography color="grey.600" variant="body1" mt={3}>
                    Using your Talkio number, dial *100*6# and follow the steps
                    to recharge your account. Your payment will be processed
                    through Mobile Money and deducted from an MTN MoMo or Airtel
                    Money account of your choosing.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="resImg">
                  <img src={HowToPayPic} alt="how to pay" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer>
          <Box py={5}>
            <Box>
              <h1 className="heading-2 text-center mb-5">
                We accept the following payment methods
              </h1>

              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                gap={3}
                pb={5}
              >
                {paymentMethodsLogos.map((item, i) => (
                  <Box
                    key={i}
                    sx={{
                      width: "220px",
                      height: "120px",
                      boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.1)",
                      p: 2,
                      borderRadius: 2,
                      img: {
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      },
                    }}
                  >
                    <img src={item?.image} alt={item?.label} />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default HowToPay;
