import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TalkioImage from "assets/img/static/bg/talkio-sim.png";
import SummaryNumber from "component/_common/plans/summaryNumber";
import SummaryPlan from "component/_common/plans/summaryPlan";
import CheckoutLayout from "component/checkout";
import useDisableBackButton from "hooks/useDisableBackButton";
import usePreventPageRefresh from "hooks/usePreventPageRefresh";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "state/store";

const Checkout: React.FC = () => {
  const navigate = useNavigate();

  useDisableBackButton();
  usePreventPageRefresh();

  const { bookingData, bookingStatus } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  const isBookingDataAvailable: boolean = useMemo(
    () =>
      Boolean(
        (bookingStatus?.packageBundleDetails ||
          bookingStatus?.buildBundleDetails) &&
          bookingStatus?.phoneDetails &&
          bookingStatus?.accountDetails &&
          bookingStatus?.bookingDetails &&
          bookingStatus?.billDetails
      ),
    [bookingStatus]
  );

  useEffect(() => {
    if (
      !bookingStatus?.phoneDetails &&
      !bookingStatus?.packageBundleDetails &&
      !bookingStatus?.buildBundleDetails &&
      !bookingStatus?.accountDetails &&
      !bookingStatus?.bookingDetails &&
      !bookingStatus?.billDetails
    ) {
      setIsLoaded(true);
      setCounter(3);
      return;
    }

    setIsLoaded(true);
  }, [bookingStatus]);

  useEffect(() => {
    if (!isBookingDataAvailable && counter > 0) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            clearInterval(timer);
            return prevCounter - 1;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isBookingDataAvailable, counter]);

  useEffect(() => {
    if (counter === 0 && isLoaded && !isBookingDataAvailable) {
      navigate(RouteConstant.PRODUCT_BUILD_BUNDLE);
    }
  }, [counter, navigate, isLoaded, isBookingDataAvailable]);

  return (
    <>
      <section>
        <MainContainer>
          <Box py={5}>
            {isLoaded ? (
              <>
                {!isBookingDataAvailable ? (
                  <>
                    {counter > 0 ? (
                      <Alert severity="error" className="fm-poppins">
                        <AlertTitle>Details not found</AlertTitle>
                        You will be automatically redirected to the package
                        bundle selection page in {counter} seconds.
                        <br /> Alternatively, you can click{" "}
                        <Link
                          to={RouteConstant.PRODUCT_BUILD_BUNDLE}
                          style={{ textDecoration: "underline" }}
                        >
                          here
                        </Link>{" "}
                        to go to the page immediately.
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <Grid container spacing={3}>
                      <Grid item lg={4} xs={12}>
                        <Typography color="grey.600" mb={3}>
                          Almost there
                        </Typography>
                        <h1 className="heading-3">Please review the info</h1>
                        <Box className="resImg">
                          <img src={TalkioImage} alt="Talkio Mobile" />
                        </Box>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <SummaryNumber
                          phoneDetails={bookingData?.phoneDetails}
                        />
                        <SummaryPlan
                          packageBundleDetails={
                            bookingData?.packageBundleDetails
                          }
                          buildBundleDetails={bookingData?.buildBundleDetails}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <CheckoutLayout />
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default Checkout;
