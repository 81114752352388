import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MainContainer from "parts/mainContainer";
import React from "react";

interface Props {
  bannerImage?: string;
  bannerVideo?: string;
  subTitle?: string;
  title?: string;
  upperTitle?: string;
}

const InnerPageTopBanner3: React.FC<Props> = ({
  bannerImage,
  bannerVideo,
  subTitle,
  title,
  upperTitle,
}) => {
  const { breakpoints } = useTheme();

  return (
    <>
      <section>
        <MainContainer>
          <Box
            sx={{
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            <Box
              className="fm-poppins"
              sx={{
                position: "relative",
                borderRadius: 5,
                overflow: "hidden",
              }}
            >
              {upperTitle || title || subTitle ? (
                <Box
                  px={{ xs: 3, md: 5 }}
                  zIndex={999}
                  sx={{
                    width: "100%",
                    maxWidth: {
                      xs: "100%",
                      sm: "60%",
                    },
                    position: "absolute",
                    top: { xs: "5%", sm: "50%" },
                    transform: { sm: "translateY(-50%)" },
                  }}
                >
                  <Box position="relative">
                    {upperTitle ? (
                      <Typography
                        fontWeight={600}
                        lineHeight={1.5}
                        className="fm-poppins fc-black"
                        sx={{
                          fontSize: {
                            xs: 18,
                            [breakpoints.up(400)]: {
                              fontSize: 20,
                            },
                            [breakpoints.up(600)]: {
                              fontSize: "18px !important",
                            },
                            [breakpoints.up(768)]: {
                              fontSize: "20px !important",
                            },
                            [breakpoints.up(1201)]: {
                              fontSize: "24px !important",
                            },
                          },
                        }}
                      >
                        {upperTitle}
                      </Typography>
                    ) : null}
                    {title ? (
                      <Typography
                        fontWeight={600}
                        lineHeight={1.3}
                        className="fm-poppins fc-black"
                        zIndex={-1}
                        sx={{
                          fontSize: {
                            xs: 30,
                            [breakpoints.up(400)]: {
                              fontSize: 42,
                            },
                            [breakpoints.up(600)]: {
                              fontSize: "24px !important",
                            },
                            [breakpoints.up(768)]: {
                              fontSize: "36px !important",
                            },
                            [breakpoints.up(1201)]: {
                              fontSize: "48px !important",
                            },
                          },
                        }}
                      >
                        {title}
                      </Typography>
                    ) : null}
                    {subTitle ? (
                      <Typography
                        fontWeight={600}
                        lineHeight={1.5}
                        className="fm-poppins fc-black"
                        sx={{
                          fontSize: {
                            xs: 18,
                            [breakpoints.up(400)]: {
                              fontSize: 20,
                            },
                            [breakpoints.up(600)]: {
                              fontSize: "18px !important",
                            },
                            [breakpoints.up(768)]: {
                              fontSize: "20px !important",
                            },
                            [breakpoints.up(1201)]: {
                              fontSize: "24px !important",
                            },
                          },
                        }}
                      >
                        {subTitle}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              ) : null}

              {bannerVideo ? (
                <Box
                  component="video"
                  src={bannerVideo}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              ) : bannerImage ? (
                <Box
                  component="img"
                  src={bannerImage}
                  sx={{
                    width: "100%",
                  }}
                />
              ) : null}
            </Box>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default InnerPageTopBanner3;
