import Box from "@mui/material/Box";
import BannerImage from "assets/img/static/bg/bundle-plans-banner.jpg";
import BannerImageMobile from "assets/img/static/bg/bundle-plans-banner-mobile.jpg";
import BundlePlansLayout from "component/products/bundle-plans";
import BuildOwnBundle from "component/_common/plans/buildOwnBundle";
import InnerPageTopBanner3 from "parts/banners/innerPageTopBanner/innerPageTopBanner3";
import MainContainer from "parts/mainContainer";
import SectionTitle from "parts/sectionTitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPhoneBookingData,
  DispatchBookingStep,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProductBundlePlans = () => {
  const Dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const isMobileDevice = useMediaQuery(breakpoints.down("sm"));

  const { bookingStatus } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  useEffect(() => {
    if (bookingStatus?.bookingDetails || bookingStatus?.billDetails) {
      Dispatch(clearPhoneBookingData());
      return;
    }
    if (bookingStatus?.phoneDetails) {
      Dispatch(DispatchBookingStep(1));
      return;
    }

    Dispatch(clearPhoneBookingData());
  }, [Dispatch, bookingStatus]);

  return (
    <>
      <InnerPageTopBanner3
        bannerImage={isMobileDevice ? BannerImageMobile : BannerImage}
        title="Connectivity"
        upperTitle="Experience Better And Faster"
      />

      <section>
        <MainContainer>
          <Box pt={5}>
            <BundlePlansLayout />
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer>
          <Box py={5}>
            <Box textAlign="center" className="fc-black">
              <SectionTitle
                title="Not seeing a plan that suits you?"
                subTitle="Don’t worry, build one that fits your needs."
              />
            </Box>

            <Box pt={3}>
              <BuildOwnBundle />
            </Box>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default ProductBundlePlans;
