import {
  Box,
  Button,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BundlePlanImageMobile from "assets/img/static/bg/bundle-plans-banner-mobile.jpg";
import BundlePlanImage from "assets/img/static/bg/bundle-plans-banner.jpg";
import HomeBanner20GBImageMobile from "assets/img/static/bg/home-banner-20gb-mobile.jpg";
import HomeBanner20GBImage from "assets/img/static/bg/home-banner-20gb.jpg";
import PremiumPlanImageMobile from "assets/img/static/bg/premium-banner-mobile.jpg";
import PremiumPlanImage from "assets/img/static/bg/premium-banner.jpg";
import TouristPlanImageMobile from "assets/img/static/bg/tourist-banner-mobile.jpg";
import TouristPlanImage from "assets/img/static/bg/tourist-banner.jpg";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface LabelProps {
  text: string;
  sx?: SxProps;
}

interface ButtonProps {
  text: string;
  link: string;
  sx?: SxProps;
}

interface SliderListProps {
  label1?: LabelProps;
  label2?: LabelProps;
  label3?: LabelProps;
  button1?: ButtonProps;
  button2?: ButtonProps;
  image?: {
    url?: string;
    sx?: SxProps;
  };
  video?: {
    url?: string;
    sx?: SxProps;
  };
}

const HomeHeader: React.FC = () => {
  const { breakpoints, palette } = useTheme();
  const isMobileDevice = useMediaQuery(breakpoints.down("sm"));

  const sliderList: SliderListProps[] = [
    {
      label1: {
        text: "Experience Better And Faster",
        sx: {
          fontSize: {
            xs: 16,
            md: 24,
          },
        },
      },
      label2: {
        text: "Connectivity",
      },
      button1: {
        text: "Get Started",
        link: RouteConstant.PRODUCTS,
      },
      button2: {
        text: "Explore Products",
        link: `${RouteConstant.HOME}#explore-products`,
      },
      image: { url: isMobileDevice ? BundlePlanImageMobile : BundlePlanImage },
    },
    {
      label1: {
        text: "Premium plans",
        sx: {
          fontSize: {
            xs: 16,
            [breakpoints.up(400)]: {
              fontSize: 22,
            },
          },
        },
      },
      label2: {
        text: "Crafted Just For You!",
        sx: {
          fontSize: {
            xs: 22,
            [breakpoints.up(400)]: {
              fontSize: 30,
            },
            [breakpoints.up(1024)]: {
              fontSize: "42px !important",
            },
            [breakpoints.up(1200)]: {
              fontSize: "54px !important",
            },
          },
        },
      },
      button1: {
        text: "Explore Plans",
        link: RouteConstant.PRODUCT_PREMIUM_PLANS,
      },
      image: {
        url: isMobileDevice ? PremiumPlanImageMobile : PremiumPlanImage,
      },
    },
    {
      label1: {
        text: "Stay Connected with our competitive Tourist Plans",
        sx: {
          fontSize: {
            xs: 14,
            [breakpoints.up(400)]: {
              fontSize: 18,
            },
            [breakpoints.up(600)]: {
              fontSize: "14px !important",
            },
            [breakpoints.up(768)]: {
              fontSize: "18px !important",
            },
            [breakpoints.up(1201)]: {
              fontSize: "20px !important",
            },
          },
        },
      },
      label2: {
        text: "Traveling to Uganda Soon?",
        sx: {
          fontSize: {
            xs: 30,
            [breakpoints.up(400)]: {
              fontSize: 42,
            },
            [breakpoints.up(600)]: {
              fontSize: "24px !important",
            },
            [breakpoints.up(768)]: {
              fontSize: "36px !important",
            },
            [breakpoints.up(1201)]: {
              fontSize: "48px !important",
            },
          },
        },
      },
      button1: {
        text: "Explore Plans",
        link: RouteConstant.PRODUCT_TOURIST_PLANS,
      },
      image: {
        url: isMobileDevice ? TouristPlanImageMobile : TouristPlanImage,
      },
    },
    {
      label1: {
        text: "Book your SIM & get",
        sx: {
          fontSize: {
            xs: 22,
            sm: 20,
            md: 30,
            lg: 48,
          },
        },
      },
      label2: {
        text: "20GB",
        sx: {
          fontSize: { xs: 48, sm: 60, lg: 166 },
          color: palette.primaryVar.main,
          lineHeight: 0.9,
          fontWeight: 700,
        },
      },
      label3: {
        text: "at only UGX 35,000",
        sx: {
          fontSize: {
            xs: 22,
            sm: 20,
            md: 30,
            lg: 48,
          },
        },
      },
      button1: {
        text: "Click to learn more",
        link: RouteConstant.PROMOTION,
        sx: {
          backgroundColor: palette.primaryVar.main,
          "&:hover": {
            backgroundColor: palette.primaryVar.main,
          },
        },
      },
      image: {
        url: isMobileDevice ? HomeBanner20GBImageMobile : HomeBanner20GBImage,
        sx: {
          backgroundPosition: "center right",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.05)",
            zIndex: 2,
          },
        },
      },
    },
  ];
  return (
    <section style={{ position: "relative" }}>
      <MainContainer>
        <Box
          sx={{
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          <Swiper
            slidesPerView={1}
            modules={[Navigation, Autoplay]}
            autoplay={{
              delay: 3000000,
              disableOnInteraction: false,
            }}
            loop={true}
            className="custom-pagination-swiper normal"
          >
            {sliderList.map(
              (
                { label1, label2, label3, button1, button2, image, video },
                i
              ) => (
                <SwiperSlide key={i}>
                  <Box
                    className="fm-poppins"
                    sx={{
                      position: "relative",
                      borderRadius: 5,
                      overflow: "hidden",
                    }}
                  >
                    {label1?.text ||
                    label2?.text ||
                    label3?.text ||
                    button1?.link ||
                    button2?.link ? (
                      <Box
                        px={{ xs: 3, md: 5 }}
                        zIndex={999}
                        sx={{
                          width: "100%",
                          maxWidth: {
                            xs: "100%",
                            sm: "60%",
                          },
                          position: "absolute",
                          top: { xs: "5%", sm: "50%" },
                          transform: { sm: "translateY(-50%)" },
                        }}
                      >
                        {label1?.text ? (
                          <Typography
                            sx={{
                              fontSize: {
                                xs: 20,
                                md: 24,
                              },
                              fontWeight: 600,
                              lineHeight: 1.5,
                              ...(label1?.sx || {}),
                            }}
                          >
                            {label1?.text}
                          </Typography>
                        ) : null}

                        {label2?.text ? (
                          <Box position="relative">
                            <Typography
                              sx={{
                                fontSize: { xs: 36, lg: 54 },
                                fontWeight: 600,
                                lineHeight: 1.3,
                                ...(label2?.sx || {}),
                              }}
                            >
                              {label2?.text}
                            </Typography>
                          </Box>
                        ) : null}

                        {label3?.text ? (
                          <Box position="relative">
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: 20,
                                  md: 24,
                                },
                                fontWeight: 600,
                                lineHeight: 1.5,
                                ...(label3?.sx || {}),
                              }}
                            >
                              {label3?.text}
                            </Typography>
                          </Box>
                        ) : null}

                        {button1?.link || button2?.link ? (
                          <Box pt={2} maxWidth={220}>
                            <Stack gap={2} direction="column">
                              {button1?.link ? (
                                <Link to={button1?.link} style={{ flex: 1 }}>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    sx={{
                                      borderRadius: "6px",
                                      fontSize: { xs: 14, lg: 16 },
                                      backgroundColor: "#1F1F1F",
                                      color: "#FFFFFF",
                                      width: "100%",
                                      minWidth: "auto",
                                      "&:hover": {
                                        backgroundColor: "#1F1F1F",
                                      },
                                      ...(button1?.sx || {}),
                                    }}
                                  >
                                    {button1?.text}
                                  </Button>
                                </Link>
                              ) : null}
                              {button2?.link && button2?.text ? (
                                <Link to={button2?.link} style={{ flex: 1 }}>
                                  <Button
                                    disableElevation
                                    variant="outlined"
                                    color="inherit"
                                    sx={{
                                      borderRadius: "6px",
                                      fontSize: { md: "16px" },
                                      borderColor: "#1F1F1F",
                                      color: "#1F1F1F",
                                      width: "100%",
                                      minWidth: "auto",
                                      ...(button2?.sx || {}),
                                    }}
                                    onClick={(e) => {
                                      const elementId =
                                        button2?.link?.split("#")[1];
                                      const element =
                                        document.getElementById(elementId);
                                      if (element) {
                                        element.scrollIntoView({
                                          behavior: "smooth",
                                        });
                                      }
                                    }}
                                  >
                                    {button2?.text}
                                  </Button>
                                </Link>
                              ) : (
                                <></>
                              )}
                            </Stack>
                          </Box>
                        ) : null}
                      </Box>
                    ) : null}

                    {image?.url ? (
                      <Box
                        component="img"
                        src={image?.url}
                        sx={{
                          width: "100%",
                        }}
                      />
                    ) : video?.url ? (
                      <Box
                        component="video"
                        src={video?.url}
                        autoPlay
                        loop
                        muted
                        playsInline
                      />
                    ) : null}
                  </Box>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </Box>
      </MainContainer>
    </section>
  );
};

export default HomeHeader;
